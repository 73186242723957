<template>
  <page>
    <card class="max-w-2xl mx-auto" :title="$t('mover.title')">
      <p class="text-xs text-red-600">{{ $t('mover.text') }}</p>
      <selector
        class="mt-4"
        i18n="mover.from"
        v-model="src"
        :options="userOptions"
        :error="errorFrom"
      />
      <selector
        class="mt-4"
        i18n="mover.to"
        v-model="dst"
        :options="userOptions"
        :error="errorTo"
      />
      <div class="mt-4" v-for="mover in movers" :key="mover.id">
        <div class="flex items-center justify-between text-xs">
          <span>{{ $t(mover.label) }}</span>
          <span>
            {{ Math.floor((progress * mover.total) / 100) }}
            /
            {{ mover.total }}
            ({{ Math.floor(progress) }}%)
          </span>
        </div>
        <div class="w-full bg-gray-200 rounded-full h-4">
          <div
            class="bg-blue-600 h-4 rounded-full transform-gpu transition-all"
            :style="{
              width: `${progress}%`
            }"
          ></div>
        </div>
      </div>
      <div v-if="moveResult">
        <div
          class="
            flex
            items-center
            justify-between
            border border-green-600
            p-4
            bg-green-100
            rounded
            mt-4
          "
          v-for="mover in movers"
          :key="mover.id"
        >
          <span>{{ $t(mover.label) }}:</span>
          <span>{{ moveResult[mover.id] }} / {{ mover.total }}</span>
        </div>
      </div>
      <div class="mt-4 flex justify-center items-center">
        <action
          variant="primary"
          :disabled="hasErrors || isBusy || isDone"
          :loading="isBusy"
          @click="moveData"
          >{{ $t('mover.action') }}</action
        >
      </div>
    </card>
  </page>
</template>

<script>
const wait = (ts = 300) => new Promise((resolve) => setTimeout(resolve, ts))

export default {
  data() {
    return {
      src: '_',
      dst: '_',
      isBusy: false,
      isDone: false,
      moveResult: null,
      progress: 0,
      estatesTotal: 0,
      imagesTotal: 0,
      contactsTotal: 0
    }
  },
  watch: {
    src(to) {
      this.isDone = false
      this.moveResult = null
      if (to === '_') {
        return
      }
      this.loadPeek(to)
    }
  },
  computed: {
    users() {
      return this.$store.state.users.items
    },
    userOptions() {
      return [
        { id: '_', label: this.$t('mover.select') },
        ...this.users.map((user) => ({
          id: user._id,
          value: user._id,
          label: `${user.name} (${user.email})${
            user.role === 'ADMIN' ? ' -- ADMIN' : ''
          }`
        }))
      ]
    },
    hasErrors() {
      return (
        this.errorFrom !== null ||
        this.errorTo !== null ||
        this.movers.reduce((sum, total) => sum + total, 0) === 0
      )
    },
    errorFrom() {
      if (this.src === '_') {
        return this.$t('mover.select')
      }
      return null
    },
    errorTo() {
      if (this.dst === '_') {
        return this.$t('mover.select')
      }
      if (this.dst === this.src) {
        return this.$t('mover.same')
      }
      return null
    },
    movers() {
      return [
        {
          id: 'estates',
          label: 'mover.estates',
          total: this.estatesTotal
        },
        {
          id: 'contacts',
          label: 'mover.contacts',
          total: this.contactsTotal
        },
        {
          id: 'images',
          label: 'mover.images',
          total: this.imagesTotal
        }
      ]
    }
  },
  methods: {
    async moveData() {
      this.doCallMoveData()
      this.progress = 0
      while (this.progress < 99) {
        const _wait = Math.floor(150 * Math.random())
        const progress =
          this.progress + (this.progress < 50 ? 1 : (100 - this.progress) / 50)
        this.progress = progress > 99 ? Math.min(progress, 100) : progress
        await wait(_wait)
      }
    },
    async doCallMoveData() {
      this.isBusy = true
      this.isDone = false
      this.moveResult = null
      try {
        const response = await this.$http.post('/owner/change', {
          src: this.src,
          dst: this.dst
        })
        if (!response?.data?.success) {
          this.$toast('danger', 'mover.error')
        } else {
          this.$toast('success', { text: 'mover.success', args: response.data })
          this.moveResult = response.data
          await this.$store.dispatch('load')
        }
      } catch (err) {
        console.error('Error while changing owner', err)
        this.$toast('danger', 'mover.error')
      } finally {
        this.isDone = true
        this.progress = 100
        this.isBusy = false
      }
    },
    async loadPeek(uid) {
      this.estatesTotal = 0
      this.imagesTotal = 0
      this.contactsTotal = 0
      this.isBusy = true
      try {
        const response = await this.$http.get(`/owner/peek/${uid}`)
        if (!response?.data?.success) {
          return
        }
        this.estatesTotal = response.data.estates
        this.imagesTotal = response.data.images
        this.contactsTotal = response.data.contacts
      } catch (err) {
        console.error('Error while peeking at items count', err)
        this.estatesTotal = 0
        this.imagesTotal = 0
        this.contactsTotal = 0
      } finally {
        this.isBusy = false
      }
    }
  }
}
</script>
